@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

* {
   font-family: 'Outfit', sans-serif;
   font-style: normal;
   // color      : #000;
}

.df {
   display: flex;
}

.jcc {
   justify-content: center;
}

.aic {
   align-items: center;
}

.tac {
   text-align: center;
}

button {
   padding: 0;
}

.view_all button,
.view_all {
   font-size: 18px;
   line-height: 24px;
   text-align: center;
   color: #1777f6;
   display: flex;
   align-items: center;
   text-decoration: underline;
   text-decoration-style: dotted;
   text-underline-offset: 8px;
   border: none;
   background-color: transparent;
   gap: 10px;

   svg {
      filter: brightness(0) saturate(100%) invert(30%) sepia(83%) saturate(2140%) hue-rotate(205deg) brightness(100%) contrast(93%);
   }
}

.book_a_demo_now {
   font-size: 24.7px;
   font-weight: 900;
   line-height: 24px;
   text-align: center;
   color: #781c68;
   display: flex;
   align-items: center;
   text-decoration: underline;
   text-decoration-style: dotted;
   text-underline-offset: 12px;
   border: none;
   background-color: transparent;

   &:hover {
      color: #5cb648;
   }
}

.buy_now {
   border-radius: 6px;
   box-shadow: inset 0px -2px 0.5px rgba(0, 0, 0, 0.24);
   background: #4caf50;
   // background: linear-gradient(180deg, #e14d2a 0%, #c52700 100%);
   text-align: center;
   color: #ffffff;
   height: 42px;
   font-size: 20px;
   border: none;
   // width        : 355px;
   min-width: 315px;
   max-width: max-content;
   font-weight: 800;
   padding: 0 20px;
   transition: all 0.3s ease-in-out;

   &:hover {
      background: transparent;
      border: 3px solid #4caf50;
      color: #4caf50;
   }
}

.home_page {
   margin-top: 70px;

   .banner_section {
      h3 {
         color: #5cb648;
         font-size: 56px;
         font-weight: 900;

         span {
            font-weight: 500;
            color: #000;
         }
      }

      .rating_content {
         display: flex;
         gap: 25px;

         figcaption {
            font-weight: 300;
         }
      }

      .book_demo_buy_now {
         display: flex;
         gap: 15px;
         align-items: center;
         flex-wrap: wrap;

         p {
            margin: 0;
            line-height: 34px;
            font-weight: 300;
         }
      }
   }

   .banner_img {
      position: relative;

      p {
         background-color: #fff;
         box-shadow: 3px 3px 10px 0 #cccccc9c;
         border-radius: 8px;
         -webkit-border-radius: 8px;
         -moz-border-radius: 8px;
         -ms-border-radius: 8px;
         -o-border-radius: 8px;
         margin: 0;
         display: inline-block;
         padding: 4px 10px;
         position: absolute;
         top: -58px;
         left: 0;
         display: flex;
         width: 100%;
      }
   }
}

.happy_client_section {
   .happy_client {
      display: flex;
      gap: 20px;
      justify-content: center;
      margin-top: 40px;
      flex-wrap: wrap;

      .slick-slider {
         width: 60%;
      }

      .happy-clients img {
         width: 57px;
      }
   }
}

.keep_your_complaint {
   background-color: #f2ffef;
   margin-top: 40px;
   padding: 25px 0;

   h4 {
      font-size: 24px;
      font-weight: 300;
      text-align: center;
      margin-bottom: 20px;

      span {
         color: #5cb648;
         font-weight: 600;
      }
   }
}

.care_business {
   margin: 60px auto;
   text-align: center;

   h4 {
      font-weight: 300;

      span {
         color: #485cb6;
         font-weight: 600;
      }
   }

   p {
      font-weight: 100;
      margin-bottom: 40px;
   }

   .care_business_card {
      // display       : flex;
      // flex-direction: column;
      // align-items   : center;

      img {
         min-height: 0px;
      }

      h5 {
         color: #485cb6;
         font-weight: 300;
         margin-top: 20px;
      }

      p {
         font-weight: 300;
         margin: 0;
      }
   }
}

.quality_management {
   background-color: #eef5ff;
   margin-top: 40px;
   padding: 40px 0;

   .row {
      display: flex;
      align-items: center;

      .qm_img {
         display: flex;
         justify-content: center;
      }

      h2 {
         color: #1777f6;
         font-weight: 100;
      }

      p {
         font-size: 16px;
         font-weight: 100;
         line-height: 30px;
      }
   }

   button {
      background: linear-gradient(0deg, #003985 0%, #1675f2 100%);
      color: #fff;
      border-radius: 6px;
      width: 230px;
      height: 48px;
      border: none;
      font-size: 20px;
      font-weight: 700;

      &:hover {
         background: transparent;
         border: 3px solid #003985;
         color: #003985;
      }
   }
}

.system_feature {
   margin-top: 50px;
   background: url('../../../src/images/home_new_design/system_feature.svg');
   background-repeat: no-repeat;
   background-size: 100%;

   h2 {
      text-align: center;
      margin-bottom: 20px;

      span {
         color: #e14d2a;
      }
   }

   p {
      text-align: center;
   }

   .tab-container {
      text-align: center;
      margin-top: 60px;

      .tab-headers {
         grid-gap: 36px;
         gap: 36px;
         display: flex;
         justify-content: center;
         width: 100%;
         overflow-x: scroll;
         white-space: nowrap;
         overflow: hidden;
         overflow-x: auto;
         margin: auto;

         &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
         }

         &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px grey;
            border-radius: 10px;
         }

         &::-webkit-scrollbar-thumb {
            background: #5cb648;
            border-radius: 10px;
         }

         .tab-header {
            border: none;
            font-size: 14px;
            background-color: transparent;

            &.active {
               background-color: #ffe5df;
               color: #e14d2a;
               padding: 4px 12px;
               border-radius: 50px;
               -webkit-border-radius: 50px;
               -moz-border-radius: 50px;
               -ms-border-radius: 50px;
               -o-border-radius: 50px;
            }
         }
      }

      .tab-content {
         .tab-panel {
            p {
               width: 58%;
               margin: 32px auto;
            }

            span {
               img {
                  width: 100%;
               }
            }
         }
      }
   }

   .book_demo_buy_now {
      margin: 50px 0;

      button {
         margin: auto;
      }
   }
}

.exclusive_offer {
   background: url('../../images/home_new_design/exclusive_banner.svg') no-repeat center/cover;
   padding: 50px 0;

   .exclusive_offer_img {
      position: relative;
   }

   .row {
      row-gap: 30px;
      align-items: center;

      :where(.card_1, .card_3) {
         background-color: #fff;
         border-radius: 12px;
         padding: 22px;

         p {
            font-size: 16px;
         }

         h2 {
            font-weight: 100;

            span {
               font-size: 16px;
               font-weight: 300;
            }
         }

         a {
            display: flex;
            color: #1777f6;
            align-items: center;
            gap: 10px;

            &:hover {
               text-decoration: none;
            }

            svg {
               filter: brightness(0) saturate(100%) invert(51%) sepia(83%) saturate(6666%) hue-rotate(209deg) brightness(106%) contrast(93%);
            }
         }
      }

      .card_1 {
         h2 {
            &:nth-child(1) {
               color: #e14d2a;

               span {
                  color: #e14d2a;
               }
            }

            &:nth-child(2) {
               color: #781c68;

               span {
                  color: #781c68;
               }
            }
         }
      }
   }

   .card_3 {
      h2 {
         color: #5cb648;
         margin: 0;
      }

      .h2_a {
         display: flex;
         align-items: end;
         justify-content: space-between;
      }
   }
}

.simple_document_form {
   margin-top: 50px;

   h2 {
      font-weight: 200;
      margin-bottom: 28px;
   }

   h4 {
      margin-bottom: 15px;
   }

   p {
      margin-bottom: 20px;
      font-size: 18px;
      line-height: 28px;
   }

   .doc {
      color: #e14d2a;
      font-weight: 600;
   }

   .form {
      font-weight: 600;
      color: #781c68;
   }

   .feedback_survey_img {
      display: flex;
      flex-wrap: wrap;
      gap: 18px;

      p.preview-text {
         display: none;
      }

      a.feedback-Survey {
         position: relative;
         width: 184px;
         height: 184px;
         overflow: hidden;
         border-radius: 6px;

         &:hover {
            p.preview-text {
               position: absolute;
               cursor: pointer;
               top: 0px;
               left: 0;
               border-bottom: 2px solid #fff;
               background-color: #ccccccb0;
               width: 100%;
               height: 100%;
               display: flex;
               align-items: center;
               justify-content: center;
               backdrop-filter: blur(2px);
            }
         }
      }
   }

   .record_form {
      margin-top: 50px;

      .buy_now {
         background: linear-gradient(0deg, #560e4a 0%, #a0388e 100%) !important;

         &:hover {
            background: transparent !important;
            border: 3px solid #560e4a;
            color: #560e4a;
            width: max-content;
         }
      }
   }
}

.packages_and_pricing {
   background: #ffffff;
   background: radial-gradient(farthest-corner at 50% 50%, #ffffff 0%, #fffbef 100%);
   margin-top: 40px;
   padding: 40px 0;

   h2 {
      font-weight: 200;
      text-align: center;

      span {
         color: #5cb648;
         font-weight: 600;
      }
   }

   p {
      text-align: center;
   }

   .view_all {
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: #1777f6;
      display: flex;
      align-items: center;
      text-decoration: underline;
      text-decoration-style: dotted;
      text-underline-offset: 12px;
      border: none;
      background-color: transparent;
      justify-content: center;
      width: 100%;
      gap: 10px;

      svg {
         filter: brightness(0) saturate(100%) invert(30%) sepia(83%) saturate(2140%) hue-rotate(205deg) brightness(100%) contrast(93%);
      }
   }

   .row {
      margin-top: 40px;
      row-gap: 40px;
      justify-content: center;

      h6 {
         margin: 0;
         font-size: 16px;
         font-weight: 100;
         line-height: 24px;
         height: 40px;
      }

      .para_img {
         h6 {
            height: unset;
            color: #757575;
            font-size: 20px;
         }

         p {
            text-align: left;
            font-size: 20px;
         }

         display: flex;
         justify-content: space-between;
         align-items: center;
      }

      .form {
         color: #757575;
         text-align: left;
         margin: 0;
      }

      .amt {
         text-align: left;
         font-size: 32px;
         color: #424242;

         span {
            font-size: 16px;
         }
      }

      .atc {
         border-radius: 50px;
         -webkit-border-radius: 50px;
         -moz-border-radius: 50px;
         -ms-border-radius: 50px;
         -o-border-radius: 50px;
         border: none;
         width: 144px;
         height: 40px;
         margin: auto;
         display: flex;
         justify-content: center;
         align-items: center;
         gap: 8px;
      }

      .pricing_1 {
         background-color: #fff;
         padding: 20px;
         border: 1px solid #e0e0e0;
         box-shadow: 0 0 21px 0 #cccccc7a;
         border-radius: 10px;
         transition: all 0.3s ease-in-out;
         -webkit-transition: all 0.3s ease-in-out;
         -moz-transition: all 0.3s ease-in-out;
         -ms-transition: all 0.3s ease-in-out;
         -o-transition: all 0.3s ease-in-out;

         h6 {
            color: #781c68;
         }

         .para_img {
            h6 {
               color: #757575;
            }
         }

         .form {
            color: #757575;
         }

         .amt {
            color: #781c68;
         }

         .atc {
            background-color: #781c68;
            color: #fff;

            svg {
               color: #fff;
            }
         }

         &:hover {
            h6 {
               color: #e14d2a;
            }

            .amt {
               color: #e14d2a;
            }

            .atc {
               background-color: #e14d2a;
               color: #fff;
            }
         }
      }

      .before_add_to_cart {
         background-color: #ffffff00;
         padding: 20px;
         border: 1px solid #eeeeee;
         border-radius: 8px;
         transition: all 0.3s ease-in-out;
         -webkit-transition: all 0.3s ease-in-out;
         -moz-transition: all 0.3s ease-in-out;
         -ms-transition: all 0.3s ease-in-out;
         -o-transition: all 0.3s ease-in-out;

         .atc {
            background-color: transparent;
            color: #781c68;

            svg {
               color: #781c68;
            }
         }

         &:hover {
            background-color: #fff;
            padding: 20px;
            border: 1px solid #e0e0e0;
            box-shadow: 0 0 21px 0 #cccccc7a;
            border-radius: 10px;
            transition: all 0.3s ease-in-out;
            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -ms-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;

            h6 {
               color: #781c68;
            }

            .para_img {
               h6 {
                  color: #757575;
               }
            }

            .form {
               color: #757575;
            }

            .amt {
               color: #781c68;
            }

            .atc {
               background-color: #781c68;
               color: #fff;

               svg {
                  color: #fff;
               }
            }

            .view_details {
               display: block;
               position: absolute;
               top: 50%;
               left: 50%;
               transform: translate(-50%, 364%);
            }
         }
      }

      .view_details {
         background-color: #f5f5f5;
         width: 192px;
         height: 56px;
         display: flex;
         justify-content: center;
         align-items: center;
         border: none;
         margin: auto;
         display: none;
      }
   }
}

.latest_documents_updates {
   padding: 40px 0;

   .title_link {
      display: flex;
      gap: 15px;

      h2 {
         font-weight: 100;

         span {
            color: #5cb648;
            font-weight: 600;
         }
      }

      p {
         font-size: 16px;
      }
   }

   .row {
      row-gap: 20px;

      .latest_doc {
         background-color: #fafafa;
         border-radius: 6px;
         -webkit-border-radius: 6px;
         -moz-border-radius: 6px;
         -ms-border-radius: 6px;
         -o-border-radius: 6px;
         padding: 14px;

         h6 {
            font-weight: 100;
            font-size: 14px;
            height: 30px;
         }

         p {
            color: #757575;
            font-size: 14px;
            margin-bottom: 10px;
         }

         .sr {
            display: flex;
            gap: 5px;

            p {
               padding: 4px 6px;
               margin: 0;
               font-size: 14.6px;
               border-radius: 6px;

               &:nth-child(1) {
                  color: #1777f6;
                  background-color: #ecf4ff;
               }

               &:nth-child(2) {
                  color: #781c68;
                  background-color: #ffeffc;
               }
            }
         }
      }
   }
}

.new_updates {
   padding: 50px 0;
   background-color: #fafafa;

   h2 {
      text-align: center;
      font-weight: 300;

      span {
         color: #5cb648;
      }
   }

   .view_all {
      display: flex;
      justify-content: center;
   }

   .row {
      justify-content: center;
      margin-top: 40px;

      img {
         width: 100%;
         border-radius: 6px;
         -webkit-border-radius: 6px;
         -moz-border-radius: 6px;
         -ms-border-radius: 6px;
         -o-border-radius: 6px;
      }

      .posted_date {
         margin-top: 10px;
         margin-bottom: 10px;
         font-size: 14px;
         color: #424242;
      }

      h3 {
         line-height: 20px;
         margin-bottom: 20px;

         a {
            font-size: 17px;
            color: #000000;
            font-weight: 300;
         }
      }

      .MsoNormal {
         display: none;
      }

      .up-content p {
         font-size: 15px;
      }

      .read_more {
         margin-top: 10px;
         display: block;

         a {
            color: #5cb648;
            font-weight: 300;
         }
      }
   }
}

.exclusive_exployee {
   margin-top: 50px;

   h2 {
      font-weight: 300;

      span {
         color: #5cb648;
      }
   }

   .ply_app_str {
      display: flex;
      gap: 10px;
      margin-top: 30px;
   }

   p {
      width: 92%;
   }
}

.faq {
   h2 {
      font-weight: 300;
      text-align: center;

      span {
         color: #5cb648;
         font-weight: 600;
      }
   }

   .accordion-with-separate-titles {
      text-align: center;

      .titles-section {
         display: flex;
         justify-content: center;
         grid-gap: 30px;
         gap: 30px;
         margin: 20px auto 50px;
         padding: 0 0 5px 0;

         .title-button {
            border: none;
            background-color: transparent;
            color: #757575;
            font-size: 18px;
            white-space: nowrap;

            &.active {
               color: #e14d2a;
               text-decoration: underline;
               text-decoration-thickness: 3px;
               text-underline-offset: 12px;
            }
         }
      }

      .accordion {
         text-align: left;

         .accordion-content {
            &.row {
               row-gap: 35px;
            }

            .nested-accordion-item {
               &.active {
                  background-color: #fff;
                  border-radius: 12px;
                  padding: 10px 20px;
                  box-shadow: 0 0 11px 0 #cccccc8a;
                  -webkit-border-radius: 12px;
                  -moz-border-radius: 12px;
                  -ms-border-radius: 12px;
                  -o-border-radius: 12px;

                  .nested-accordion-header {
                     background-color: transparent;
                     padding: 0;
                     color: #e14d2a;
                  }
               }

               .nested-accordion-content {
                  font-size: 15px;
                  line-height: 26px;
                  font-weight: 100;
               }

               .nested-accordion-header {
                  border: none;
                  background-color: #fafafa;
                  width: 100%;
                  text-align: left;
                  padding: 6px 11px;
                  height: 56px;
                  border-radius: 8px;
               }
            }
         }
      }
   }
}

.start_your_business {
   background-color: #781c68;
   color: #fff;
   padding: 40px 0;

   .left_side {
      display: flex;
      justify-content: end;
   }

   h2 {
      font-weight: 300;
      color: #fff;
   }

   p {
      width: 84%;
      font-size: 14px;
      line-height: 24px;
   }

   .book_a_demo_now {
      color: #fff;
      margin-top: 40px;

      &:hover {
         color: #5cb648;
      }
   }
}

@media screen and (max-width: 1200px) {
   .home_page .banner_section h3 {
      font-size: 44px;
   }

   .home_page .banner_img {
      margin-top: 80px;
   }

   .system_feature .tab-container .tab-headers {
      justify-content: start;
   }

   .valid {
      right: 137px;
      top: 53px;
   }

   .discount {
      font-size: 82px;
      top: 95px;
      right: 68px;
   }
}

@media screen and (max-width: 992px) {
   .home_page {
      margin-top: 20px;
   }

   .simple_document_form .record_form {
      flex-direction: column-reverse;
      gap: 20px;
   }

   .home_page .banner_section h3,
   .quality_management .row h2,
   .latest_documents_updates p,
   .care_business p,
   .start_your_business h2,
   .system_feature p,
   .packages_and_pricing p {
      br {
         display: none;
      }
   }

   .exclusive_exployee .row {
      gap: 20px;
   }

   .start_your_business .left_side {
      justify-content: start;
   }

   .exclusive_exployee .ee_phone {
      width: 50%;
      display: flex;
      margin: auto;
   }

   .system_feature .tab-container .tab-content .tab-panel p,
   .exclusive_exployee p,
   .start_your_business p {
      width: 100%;
   }

   .care_business {
      width: unset;
   }

   .home_page .banner_img img[alt='Banner Images'] {
      width: 50%;
      margin: auto;
      display: flex;
   }

   .valid {
      right: 83px;
      top: 25px;
   }

   .discount {
      font-size: 56px;
      top: 72px;
      right: 68px;
   }

   .packages_and_pricing .row .view_details {
      width: 132px;
      height: 29px;
      display: none;
   }

   .packages_and_pricing .row .before_add_to_cart:hover .view_details {
      transform: translate(-50%, 364%);
   }
}

@media screen and (max-width: 768px) {
   .care_business p {
      br {
         display: none;
      }
   }

   .home_page .banner_section .book_demo_buy_now {
      justify-content: center;
   }

   .care_business .row {
      row-gap: 20px;
   }

   .quality_management .row h2 {
      margin-top: 20px;
   }

   .simple_document_form .feedback_survey_img {
      justify-content: center;
   }

   .exclusive_exployee {
      p {
         width: 100%;
      }
   }

   .system_feature .tab-container .tab-content .tab-panel span img {
      height: auto;
   }

   .home_page .banner_section .book_demo_buy_now img {
      display: none;
   }
}

@media screen and (max-width: 767px) {
   .valid {
      right: 163px;
      top: 65px;
   }

   .discount {
      font-size: 86px;
      top: 111px;
      right: 88px;
   }
}

@media screen and (max-width: 500px) {
   .home_page .banner_section h3 {
      font-size: 32px;
   }

   p {
      font-size: 14px;
   }

   .home_page .banner_img img[alt='Banner Images'] {
      width: 100%;
   }

   .home_page .banner_img {
      flex-direction: column-reverse;
      display: flex;
   }

   .home_page .banner_img p {
      position: unset;
   }

   .happy_client_section .happy_client p {
      br {
         display: none;
      }
   }

   .quality_management .row .qm_img img[alt='Quality Management'] {
      width: 100%;
   }

   .simple_document_form h2,
   .quality_management .row h2 {
      font-size: 22px;
   }

   .quality_management .book_demo_buy_now {
      width: 100%;
      justify-content: center !important;
      flex-wrap: wrap;

      // p {
      //    font-weight: 600;
      // }

      // h6 {
      //    font-weight: 100 !important;
      // }
   }

   .quality_management .book_demo_buy_now img {
      display: none;
   }

   .simple_document_form .record_form .buy_now {
      width: 100%;
      font-size: 16px;
   }

   .packages_and_pricing h2 {
      font-size: 24px;
   }

   .latest_documents_updates .title_link {
      flex-wrap: wrap;
   }

   .start_your_business .left_side img {
      width: 100%;
   }

   .exclusive_offer .exclusive_offer_img {
      display: none;
   }
   .book_demo_buy_now {
      width: 100% !important;
   }
   .buy_now,
   .quality_management button,
   .view_doc_list,
   .download_exp_doc,
   .Buy-LicenceBut,
   .price-details button {
      border-radius: 6px !important;
      width: 100% !important;
      max-width: 100%;
      margin-top: 9px;
   }
   .domiciliary_care_head .d-detail-page .d-licence-left_head {
      width: 100% !important;
   }
}

.policy_procedure_category {
   margin: 20px 0;
   cursor: pointer;
   .row {
      gap: 10px;
      justify-content: center;
      h6 {
         font-size: 13px;
         margin: 0;
         padding: 10px;
         font-weight: 600;
         width: 100%;
         height: 100%;
         border-radius: 6px;
         display: flex;
         flex-wrap: wrap;
         gap: 10px;
         align-items: center;
         
      }
      .policy_procedure_category_child {
         width: 200px;
         transition: all 0.3s ease-in-out;
         h6{
            span{
               width: 100px;
            }
         }
         &:nth-child(6n + 1) {
            .policy_procedure_category_g_child {
               border:1px solid #dad9ce;
               background-color: #fdfbef;
               &:hover{
                  filter: drop-shadow(0 0 0.75rem #dad9ce);
                  }
            }
         }
         &:nth-child(6n + 2) {
            .policy_procedure_category_g_child {
               border:1px solid #cedad3;
               background-color: #effdf4;
               &:hover{
                  filter: drop-shadow(0 0 0.75rem #cedad3);
                  }
            }
         }
         &:nth-child(6n + 3) {
            .policy_procedure_category_g_child {
               border:1px solid #ced4da;
               background-color: #eff6fd;
               &:hover{
                  filter: drop-shadow(0 0 0.75rem #ced4da);
                  }
            }
         }
         &:nth-child(6n + 4) {
            .policy_procedure_category_g_child {
               border:1px solid #dacece;
               background-color: #fdefef;
               &:hover{
                  filter: drop-shadow(0 0 0.75rem #dacece);
                  }
            }
         }
         &:nth-child(6n + 5) {
            .policy_procedure_category_g_child {
               background-color: #f6effd;
               border:1px solid #d4ceda;
               &:hover{
                  filter: drop-shadow(0 0 0.75rem #d4ceda);
                  }
            }
         }
         &:nth-child(6n + 6) {
            .policy_procedure_category_g_child {
               border:1px solid #dad7ce;
               background-color: #fdf9ef;
               &:hover{
                  filter: drop-shadow(0 0 0.75rem #dad7ce);
                  }
            }
         }
      }
   }
}
